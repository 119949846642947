import Header from '../../components/laparejaperfecta/Header/index';
import Opticas from '../../components/laparejaperfecta/Opticas/index';
import Footer from '../../components/laparejaperfecta/Footer/index';
import Bootstrap5 from '../../components/call-to-bootstrap/index';
import CallStyles from '../../components/call-to-styles/index';
import { useEffect } from 'react';

const call = {
  css: 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css',
  js: 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js'
};

const Style = () => {
  useEffect(() => {
    import('./styles.scss');
  }, []);
  return null;
}

function LaParejaPerfecta(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return (
    <div className='la-pareja-perfecta'>
      <CallStyles style={Style} />
      <Bootstrap5 cssCall={call.css} jsCall={call.js} />
      <Header />
      <Opticas />
      <Footer />
    </div>
  );
}

export default LaParejaPerfecta;
