import BannerDesk from '../../../images/headerdesktop.webp';
import BannerMob from '../../../images/headermobile.webp';

function Header() {
  return (
    <header>
    <div>
      <img className="img-fluid w-100 d-none d-sm-block padding-custom-desktop" src={BannerDesk} alt="ACUVUE" />
      <img className="img-fluid w-100 d-block d-sm-none" src={BannerMob} alt="ACUVUE" />
    </div>
  </header>
  );
}

export default Header;
