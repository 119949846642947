import React, { useState, useEffect } from "react";
import MapIcon from "../../../images/point.webp";
import "select2";
import $ from "jquery";

const Opticas = ({ data }) => {
	const [filtroZonas, setZonas] = useState("");
	const [opticasFiltradas, setOpticasFiltradas] = useState([]);
	const [showNoOpticas, setShowNoOpticas] = useState(false);

	const actualizarURL = () => {
		const queryParams = new URLSearchParams();
		if (filtroZonas) {
			queryParams.set("estacion", filtroZonas);
		}
		const nuevaURL = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.replaceState({}, "", nuevaURL);
	};

	const leerParametrosURL = () => {
		const parametrosURL = new URLSearchParams(window.location.search);
		const zonaParam = parametrosURL.get("estacion") || "";

		// Evita actualizaciones innecesarias de estado
		if (zonaParam !== filtroZonas) {
			setZonas(zonaParam);
			$("#zonitas").val(zonaParam).trigger("change");
		}
	};
	useEffect(() => {
		$("#zonitas")
			.select2({
				placeholder: "Selecciona una Estación",
				width: "100%",
			})
			.on("change", (e) => setZonas(e.target.value));

		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		leerParametrosURL();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		actualizarURL();
		// eslint-disable-next-line
	}, [filtroZonas]);

	useEffect(() => {
		if (filtroZonas === "") {
			setOpticasFiltradas([]);
			setShowNoOpticas(false);
		} else {
			const opticasFiltradas = data.filter((item) => {
				return item.estacion.includes(filtroZonas);
			});
			setOpticasFiltradas(opticasFiltradas);
			setShowNoOpticas(opticasFiltradas.length === 0);
		}
	}, [filtroZonas, data]);
	return (
		<div>
			<section className="container mb-5">
				{/*selector de ubicaciones*/}
				<div className="container wrapper-selector-ubicaciones pt-4">
					<div className="row justify-content-center mt-4 mb-5">
						<div className="col text-center">
							<div className="input-group mb-10">
								<select className="custom-select rounded-0" id="zonitas" defaultValue={filtroZonas}>
									<option></option>
									<option value="congresoDeTucuman">Congreso de Tucumán</option>
									<option value="juramento">Juramento</option>
									<option value="jHernandez">José Hernández</option>
									<option value="palermo">Palermo</option>
									<option value="olleros">Olleros</option>
									<option value="bulnes">Bulnes</option>
									<option value="aguero">Agüero</option>
									<option value="pueyrredon">Pueyrredón</option>
									<option value="fMedicina">F. Medicina</option>
									<option value="callao">Callao</option>
									<option value="tribunales">Tribunales</option>
									<option value="carlosPellegrini">Carlos Pellegrini</option>
									<option value="nueveDeJulio">9 de Julio</option>
									<option value="catedral">Catedral</option>
								</select>
							</div>
						</div>
					</div>

					{/* locales por zona */}
					<div id="zonitas">
						<div className="row justify-content-center">
							{opticasFiltradas.map((item, index) => (
								<div key={index} className="col-12 col-sm-5 col-md-3 m-2 caja border">
									<a className="a-style" target="_blank" href={item.link} rel="noreferrer">
										<div className="card-body my-4 d-flex flex-row justify-content-between align-items-center px-3">
											<div>
												<h5 className="card-title font_ANDB nombreSucursal">
													{item.nombre}
												</h5>
												<h6 className="card-subtitle text-muted font_ANR">
													{item.direccion}
												</h6>
											</div>
											<div className="img">
												<img src={MapIcon} className="rounded icono-pointer" alt="imagen point" />
											</div>
										</div>
									</a>
								</div>
							))}
							{showNoOpticas && (
								<p className="colorcito2 mt-2 mb-2">
									No se encuentran ópticas disponibles en esta zona.
								</p>
							)}
						</div>
					</div>
					{/* Modal compartir*/}
					<div className="modal fade w-100" id="compartirModal" tabIndex="-1" aria-hidden="true">
						<div className="modal-dialog modal-dialog-centered">
							<div className="modalCompartir">
								<div className="modal-content p-3">
									{/*header*/}
									<div className="modal-header flex-column border-0">
										<button type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">
												<img src="images/close.webp" alt="" width="23px" />
											</span>
										</button>
										<div className="modalCompartirHeader">
											<h6 className="modal-title mt-3" id="exampleModalLabel">
												<strong>Guardá tu opticá más cercana</strong>
											</h6>
											<p className="headerp mt-n2 mb-0">
												o enviala a quién quieras.
											</p>
										</div>
										<div className="col-md-12 pb-3">
											<div className="row">
												<a href="/"
													className="col-6 btn btn-secondary icono-email d-flex justify-content-center align-items-center py-3">
													<span></span>
												</a>
												<a href="/"
													className="col-6 btn btn-outline-secondary icono-whatsapp d-flex justify-content-center align-items-center py-3">
													<span></span>
												</a>
											</div>
										</div>
									</div>
									{/*Body*/}
									<div className="modal-body pt-3 mt-3">
										<form action="">
											{/*Correo*/}
											<div className="form-group mb-3">
												<label forhtml="basic-url" className="textoModalCupon">
													Ingresa tu correo electronico
												</label>
												<input type="text" className="form-control" placeholder="tuemail@gmail.com"
													id="basic-url" aria-describedby="basic-addon3" />
											</div>
											{/*terminos y condiciones*/}
											<div className="form-group form-check contenedor-tyc">
												<div>
													<input type="checkbox" className="form-check-input" id="exampleCheck1" />
												</div>
												<label className="form-check-label mt-n2" forhtml="exampleCheck1">
													Acepto los términos y condiciones.
												</label>
											</div>
											<button type="submit"
												className="btn mx-auto d-table btn-enviar px-4 px-sm-5 text-uppercase font-weight-bold py-2 btn-sm">
												Enviar
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Opticas;
