import React, { useState, useEffect } from "react";
import MapIcon from "../../../images/map.svg";
import "select2";
import $ from "jquery";

const Opticas = ({ data }) => {
	const [filtroOpticas, setOpticas] = useState("");
	const [filtroZonas, setZonas] = useState("");
	const [opticasFiltradas, setOpticasFiltradas] = useState([]);
	const [showNoOpticas, setShowNoOpticas] = useState(false);

	const actualizarURL = () => {
		const queryParams = new URLSearchParams();
		if (filtroOpticas) {
			queryParams.set("optica", filtroOpticas);
		}
		if (filtroZonas) {
			queryParams.set("zona", filtroZonas);
		}
		const nuevaURL = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.replaceState({}, "", nuevaURL);
	};

	const leerParametrosURL = () => {
		const parametrosURL = new URLSearchParams(window.location.search);
		const opticaParam = parametrosURL.get("optica") || "";
		const zonaParam = parametrosURL.get("zona") || "";

		// Evita actualizaciones innecesarias de estado
		if (opticaParam !== filtroOpticas || zonaParam !== filtroZonas) {
			setOpticas(opticaParam);
			setZonas(zonaParam);
			$("#opticaz").val(opticaParam).trigger("change");
			$("#zonitas").val(zonaParam).trigger("change");
		}
	};
	useEffect(() => {
		$('#zonitas').select2({
			placeholder: "¿En dónde estás?",
			width: "100%",
		}).on('change', (e) => setZonas(e.target.value))
		$('#opticaz').select2({
			placeholder: "Ópticas adheridas",
			width: "100%",
		}).on('change', (e) => setOpticas(e.target.value))
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		leerParametrosURL();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		actualizarURL();
		// eslint-disable-next-line
	}, [filtroOpticas, filtroZonas]);

	useEffect(() => {
		if (filtroOpticas === "" && filtroZonas === "") {
			setOpticasFiltradas([]);
			setShowNoOpticas(false);
		} else {
			const opticasFiltradas = data.filter((item) => {
				return (
					item.selectorOne.includes(filtroOpticas) &&
					item.selectorDos.includes(filtroZonas)
				);
			});
			setOpticasFiltradas(opticasFiltradas);
			setShowNoOpticas(opticasFiltradas.length === 0)
		}
	}, [filtroOpticas, filtroZonas, data]);

	const mapita = (link) => {
		// alert("Por el poder del prisma Lunar");
		const mapaModal = document.getElementById("mapaz");
		mapaModal.src = link;
	}

	return (
		<div>
			<div className="container mt-3 p-0">
				<div className="row m-0">
					<div className="col-12 col-sm-6 col-lg-3 mt-3 mb-3 pe-lg-0">
						<select
							id="opticaz"
							defaultValue={filtroOpticas}
						>
							<option value="">Ópticas adheridas</option>
							<option value="grupolof">Grupo LOF</option>
							<option value="hipervision">Hipervisión/Optilook</option>
							<option value="opticassociales">Ópticas Sociales</option>
						</select>
					</div>
					<div className="col-12 col-sm-6 col-lg-3 mt-3 mb-3 pe-lg-0">
						<select
							id="zonitas"
							defaultValue={filtroZonas}
						>
							<option value="">¿En dónde estás?</option>
							<option value="caba">CABA</option>
							<option value="gbanorte">GBA Norte</option>
							<option value="gbaoeste">GBA Oeste</option>
							<option value="gbasur">GBA Sur</option>
						</select>
					</div>
				</div>
			</div>
			<div className="container d-flex flex-wrap">
				{opticasFiltradas.map((item, index) => (
					<div key={index} className="col-12 col-md-6 col-lg-3 p-0">
						<a data-bs-toggle="modal" data-bs-target="#mapa" href="#mapa" target="_blank" rel="noreferrer" onClick={() => mapita(item.link)}>
							<div className="opticas-container row">
								<div className="col text-container">

									<p>
										<span className="colorcito1">{item.optica}</span>{" "}
										{item.localidad}
									</p>
									<p>{item.direccion}</p>
								</div>
								<div className="col-2 map-icon text-end">
									<img src={MapIcon} alt="icono de mapa" />
								</div>
							</div>
						</a>
					</div>
				))}
				{showNoOpticas && <p className="colorcito2 mt-2 mb-2">No se encuentran ópticas disponibles en esta zona.</p>}
			</div>
			<div className="modal fade" id="mapa" tabIndex="-1" aria-labelledby="mapaLabel" aria-hidden="true">
				<div className="modal-dialog d-flex justify-content-center">
					<div className="modal-content">

						<h5 className="modal-title" id="voucherLabel">MAPA</h5>
						<iframe id="mapaz" width="600" height="450" src="" title="mapa" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
			</div>
		</div>
	);
};



export default Opticas;
