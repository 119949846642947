import React from 'react';
import ReactDOM from 'react-dom/client';
import EventosAcuvue from './pages/eventosacuvue/index';
import LaParejaPerfecta from './pages/laparejaperfecta/index';
import Carrera from './pages/carrera10klof/index';
import Sportclub from './pages/sportclub/index';
import "./scss/styles.scss";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Error from './pages/error-page/error-page';


const router = createBrowserRouter([
  {
    path: "/",
    element: <EventosAcuvue title="Eventos Acuvue" />,
    errorElement: <Error title="Error 404" />,
  },
  {
    //lazy:
    path: "/carrera10klof",
    element: <Carrera title="Eventos Acuvue | Carrera 10k LOF" />,
  },
  {
    path: "/laparejaperfecta",
    element: <LaParejaPerfecta title="Eventos Acuvue | La pareja perfecta" />,
  },
  {
    path: "/vitaSportClub",
    element: <Sportclub title="Eventos Acuvue | Vita Sport Club" />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
