import Eye1 from "../../../images/eye1.webp";
import Eye2 from "../../../images/eye2.webp";
import Eye3 from "../../../images/eye3.webp";
import Eye4 from "../../../images/eye4.webp";
import Eye5 from "../../../images/eye5.webp";

function Lentillas() {
  return (
    <div className="bg-gray-mod">
      <div className="container py-mod px-3 px-lg-4">
        <div className="d-flex flex-row flex-wrap px-3 justify-content-center">
          <div className="d-flex width-col-eye flex-wrap flex-sm-nowrap">
            <div>
              <img className="img-eye" src={Eye1} alt="" />
            </div>
            <div className="pt-2 pt-sm-4 pl-2 pl-sm-3">
              <h5 className="h5-lente-eye font_ANDB">ANTIOXIDANTES</h5>
              <p className="sub-lente-eye font_ANDB">PARA MANTENER LA COMODIDAD</p>
              <p className="d-none d-sm-block p-lente-eye font_ANR">
                Aprovechamos el poder de los antioxidantes y los incorporamos a
                nuestros lentes de contacto, con una capacidad antioxidante
                cuatro veces superior a la de la vitamina E2.² Esto ayuda a
                mantener en su estado natural un importante componente de la
                película lagrimal para garantizar la comodidad durante el uso de
                los lentes.
              </p>
            </div>
            <div className="d-block d-sm-none mt-3">
              <p className="p-lente-eye font_ANR">
                Aprovechamos el poder de los antioxidantes y los incorporamos a
                nuestros lentes de contacto, con una capacidad antioxidante
                cuatro veces superior a la de la vitamina E2.² Esto ayuda a
                mantener en su estado natural un importante componente de la
                película lagrimal para garantizar la comodidad durante el uso de
                los lentes.
              </p>
            </div>
          </div>
          <div className="d-flex width-col-eye flex-wrap flex-sm-nowrap margin-mod">
            <div className="">
              <img className="img-eye" src={Eye2} alt=""></img>
            </div>
            <div className="pt-2 pt-sm-4 pl-2 pl-sm-3">
              <h5 className="h5-lente-eye font_ANDB">AGENTE HUMECTANTE</h5>
              <p className="sub-lente-eye font_ANDB">PARA UNA MEJOR HIDRATACIÓN^</p>
              <p className="d-none d-sm-block p-lente-eye font_ANR">
                Nuestros lentes de contacto incorporan un avanzado ingrediente
                humectante para ayudar a que tus ojos se mantengan hidratados
                durante todo el día.¹³
              </p>
            </div>
            <div className="d-block d-sm-none mt-3">
              <p className="p-lente-eye font_ANR">
                Nuestros lentes de contacto incorporan un avanzado ingrediente
                humectante para ayudar a que tus ojos se mantengan hidratados
                durante todo el día.¹³
              </p>
            </div>
          </div>
          <div className="d-flex flex-column width-col-eye">
            <div className="d-flex flex-nowrap">
              <div className="">
                <img className="img-eye" src={Eye3} alt=""></img>
              </div>
              <div className="pt-2 pt-sm-4 pl-2 pl-sm-3">
                <h5 className="h5-lente-eye font_ANDB">
                  ESTABILIZACIÓN MEDIANTE EL  <br /> PARPADEO PARA LENTES
                  TÓRICOS:
                </h5>
                <p className="sub-lente-eye font_ANDB">
                  VISIÓN ESTABLE PARA PACIENTES ASTÍGMATAS
                </p>
                <p className=" d-none d-sm-block p-lente-eye font_ANR">
                  Los pacientes con astigmatismo necesitan que sus lentes se
                  mantengan estables a lo largo del día, pero los movimientos
                  naturales de cabeza y ojos pueden hacerlos girar. Nuestros
                  lentes para astigmatismo tienen 4 zonas de estabilización que
                  trabajan de forma natural con los párpados, aprovechando la
                  fuerza del parpadeo, ayudando a mantener los lentes en su
                  lugar.
                </p>
              </div>
            </div>
            <div className="d-block d-sm-none mt-3">
              <p className="p-lente-eye font_ANR">
                Los pacientes con astigmatismo necesitan que sus lentes se
                mantengan estables a lo largo del día, pero los movimientos
                naturales de cabeza y ojos pueden hacerlos girar. Nuestros
                lentes para astigmatismo tienen 4 zonas de estabilización que
                trabajan de forma natural con los párpados, aprovechando la
                fuerza del parpadeo, ayudando a mantener los lentes en su lugar.
              </p>
            </div>
          </div>
          <div className="d-flex width-col-eye flex-wrap flex-sm-nowrap mt-4 margin-mod">
            <div className="">
              <img className="img-eye" src={Eye4} alt=""></img>
            </div>
            <div className="pt-2 pt-sm-4 pl-2 pl-sm-3">
              <h5 className="h5-lente-eye font_ANDB">BORDE INFINITO</h5>
              <p className="sub-lente-eye font_ANDB">PARA UNA MAYOR COMODIDAD</p>
              <p className="d-none d-sm-block p-lente-eye font_ANR">
                Nuestros lentes de contacto tienen un borde ultrafino que se
                ajusta a la forma natural del ojo para garantizar una máxima
                comodidad y una sensación casi imperceptible al usar los lentes.
              </p>
            </div>
            <div className="d-block d-sm-none mt-3">
              <p className="p-lente-eye font_ANR">
                Nuestros lentes de contacto tienen un borde ultrafino que se
                ajusta a la forma natural del ojo para garantizar una máxima
                comodidad y una sensación casi imperceptible al usar los lentes.
              </p>
            </div>
          </div>
          <div className="d-flex width-col-eye flex-wrap flex-sm-nowrap mt-4">
            <div className="">
              <img className="img-eye" src={Eye5} alt=""></img>
            </div>
            <div className="pt-2 pt-sm-4 pl-2 pl-sm-3">
              <h5 className="h5-lente-eye font_ANDB">PROTECCIÓN UV**</h5>
              <p className="sub-lente-eye font_ANDB">PARA LA SALUD OCULAR</p>
              <p className="d-none d-sm-block p-lente-eye font_ANR">
                Estamos comprometidos en ayudar a preservar tu salud ocular,
                incorporando un avanzado ingrediente de absorción UV que ayuda a
                bloquear la radiación UV.
              </p>
            </div>
            <div className="d-block d-sm-none mt-3">
              <p className="p-lente-eye font_ANR">
                Estamos comprometidos en ayudar a preservar tu salud ocular,
                incorporando un avanzado ingrediente de absorción UV que ayuda a
                bloquear la radiación UV.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-4 px-4 px-sm-5">
        <p className="p-byc text-center font_ANR mb-4 mb-sm-5">
          1. Datos JJV registrados en 2018 ® Desempeño clínico de las lentes de
          contacto de la marca y características generales del material. 2.
          Conforme se demuestra en un estudio de laboratorio; en masa. 3. Ningún
          otro fabricante de lentes de contacto emplea PVP
          (polivinilipirrolidona) de alto peso molecular en sus lentes ni las
          tecnologías usadas para combinarla con los diferentes materiales de
          los lentes de contacto. Es una exclusividad de la marca ACUVUE® **
          ADVERTENCIA: LOS LENTES DE CONTACTO CON ABSORCIÓN UV no sustituyen los
          anteojos protectores con absorción UV tales como las gafas de
          proteccíon o anteojos para el sol con absorción UV, debido a que no
          cubren completamente el ojo y el área de alrededor. Debe continuar
          usando lentes con absorción UV, según lo indicado. NOTA: La exposición
          a largo plazo a la radiación UV es uno de los factores de riesgo
          asociados con las cataratas. La exposición se basa en una serie de
          factores, tales como las condiciones ambientales (altitud, geografía,
          nubosidad) y los factores personaIes (alcance y naturaleza de las
          actividades al aire libre). Los.lentes de contacto con bloqueador UV
          ayudan a proporcionar protección contra la radiación UV perjudical.
          Sin embargo, no se han realizado estudios clínicos para demostrar que
          el uso de lentes de contacto con bloqueador UV reduce el riesgo de
          desarrollar cataratas u otros trastornos de la visión. <br />{" "}
          Consulte a su profesional de la visión para obtener más informácion.
          <br />* Sólo Ios lentes de contacto de prueba son sin cargo.
          Disponible en profesionales de la visión adheridos al programa. No
          están incluidos costos de consulta, exámenes, adaptación y adquisición
          de lentes de contacto. Los lentes de contacto de prueba sin cargo
          forman parte del proceso de adaptación y no pueden ser entregados
          fuera de este procedimiento. ^ No aplica a los lentes de contacto
          ACUVUE® 2 ¨ El agente humectante reproduce las principaIes funciones
          de la película lagrimal, ayudando a mantener la hidratación en la
          superficie y por todo el lente.
        </p>
      </div>
    </div>
  );
}

export default Lentillas;
