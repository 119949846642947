import Prods from '../../../images/prods.webp';
import LoF from '../../../images/LoF.webp';
import Voucher from '../../../images/voucherImg.webp';
import Close from '../../../images/close.webp'
import ReactGA from "react-ga4";
ReactGA.initialize("G-E3WG9B2NTJ");
function Promo() {
    const handleDownload = () => {
        window.open("VOUCHER.pdf", "_blank");
        ReactGA.event({
            category: "Download",
            action: "Download voucher Carrera10klof",
        });
    }

    return (
        <>
            <div className="containerMod mx-auto">
                <section>
                    <div className="d-flex flex-column justify-content-center align-items-center mt-3 mb-5 overVou">
                        <div className="bgPromo d-flex justify-content-center align-items-center mt-4 mb-2 mModL">
                            <h2 className="text-uppercase text-white text-center font_ANB mb-0 mModR">¡Promo imperdible!</h2>
                        </div>
                        <div className="text-center mt-4">
                            <img className="imgLof" src={LoF} alt="" />
                            <p className="text-center font_ANR font-lof colorBlue mt-3">Visitá tu sucursal <br /><span
                                className="font_ANB ">LOF</span> más cercana </p>
                        </div>
                        <div className="text-center mt-5 w-100 divOportu">
                            <img className="w-100 pr-3" src={Prods} alt="" />
                            <p className="text-center font_ANR font-prods colorBlue mt-3 mb-4">
                                Con la compra de tu set de lentes de contacto ACUVUE®,
                                <br /><span className="font_ANB ">llevate un 30% de descuento en Lentes de Sol</span>
                            </p>
                            <p className="text-center font_ANB font-oportu colorBlue text-uppercase mt-4">
                                No te pierdas <br />esta oportunidad
                            </p>
                            <button className="btn-pedi text-center py-3 mb-4 mt-4 d-inline-block font_ANB"
                                data-toggle="modal"
                                data-target="#vouchercarrera"
                            >
                                ACCEDÉ AL DESCUENTO
                            </button>
                        </div>

                    </div>
                </section>
                <section>
                    <div className="modal flex-column justify-content-center align-items-center divVouch mt-5 " tabIndex="-1" role="dialog" id="vouchercarrera">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="p-3">
                                    <a href="https://eventosacuvue.com/carrera10klof" className="ml-auto mt-0 mt-sm-2">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true"><img className="img-close-x" src={Close} alt="" /></span>
                                        </button>
                                    </a>
                                </div>
                                <div className="modal-body d-flex flex-column justify-content-center align-items-center divVouch  mx-4">
                                    <div className="">
                                        <h1 className="text-uppercase text-center font_ANB font-vouch colorBlue mt-3">¡felicitaciones!</h1>
                                        <p className="font_ANR text-uppercase text-center font-vouch colorBlue mt-3">
                                            Ya tenés tu cupón de descuento
                                        </p>
                                        <hr className="w-75 hrVouch" />
                                        <p className="font_ANR text-uppercase text-center font_ANB font-vouch2 colorBlue mt-3">
                                            Podés encontrarlo en tus descargas
                                        </p>
                                    </div>
                                    <div className="w-100 px-3 imgVou">
                                        <img src={Voucher} alt="" className="w-100 img-fluid" />
                                    </div>

                                    <button className="btn-pedi text-center text-white py-3 mb-4 mt-3 d-inline-block font_ANB"
                                        onClick={handleDownload}
                                    >
                                        DESCARGAR
                                    </button>
                                    <a className="btn-pedi btn-out-vou text-center py-3 mb-4 mt-3 d-inline-block font_ANB"
                                        href="https://eventosacuvue.com/" role="button">
                                        VER SUCURSALES
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
}

export default Promo;