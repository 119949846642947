import { useEffect } from 'react';

function Bootstrap({ cssCall, jsCall, jqueryCall }) {
    useEffect(() => {

        const styleLink = document.createElement("link");
        styleLink.rel = "stylesheet";
        styleLink.href = cssCall;

        const scriptLink = document.createElement("script");
        scriptLink.src = jsCall;

        document.head.appendChild(styleLink);
        let scriptJquery;
        if (jqueryCall) {
            scriptJquery = document.createElement("script");
            scriptJquery.src = jqueryCall;
            scriptJquery.crossOrigin = "anonymous";
            scriptJquery.integrity = "sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj";

            document.head.appendChild(scriptJquery);
            document.body.appendChild(scriptLink);
        } else {
            document.body.appendChild(scriptLink);

        }
        scriptLink.src = jsCall;

        document.head.appendChild(styleLink);
        document.body.appendChild(scriptLink);


        return () => {
            document.head.removeChild(styleLink);
            document.body.removeChild(scriptLink);
            if (jqueryCall) {
                document.head.removeChild(scriptJquery);
            }
        }

    }, [cssCall, jsCall, jqueryCall])
}
export default Bootstrap;