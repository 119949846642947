
function Footer(){
    return(
        <>
            <div className="containerMod mx-auto">
                <div className="container pb-4 px-4 px-sm-5 bg-gray-mod pt-5">
                    <p className="p-byc text-left font_ANR px-2 mb-4 mb-sm-5">
                        1. Datos JJV registrados en 2018® Desempeño clínico de las lentes de contacto de la marca y
                        características generales del material. 2. Conforme se demuestra en un estudio de laboratorio: en
                        masa.
                        3. Ningún otro fabricante de lentes de contacto emplea PVP (polivinilpropilidona) de alto peso
                        molecular
                        en sus lentes ni las tecnologías usadas para combinarla con los diferentes materiales de los lentes
                        de
                        contacto. Es una exclusividad de la marca ACUVUE®** ADVERTENCIA: LOS LENTES DE CONTACTO CON
                        ABSORCIÓN UV
                        no sustituyen los anteojos protectores con absorción UV tales como las gafas de protección o
                        anteojos
                        para el sol con absorción UV, debido a que no cubren completamente el ojo y el área de alrededor.
                        Debe
                        continuar usando lentes con absorción UV, según lo indicado. NOTA: La exposición a largo plazo a la
                        radiación UV es uno de los factores de riesgo asociados con las cataratas. La exposición se basa en
                        una
                        serie de factores, tales como las condiciones ambientales (altitud, geografía, nubosidad) y los
                        factores
                        personales (alcance y naturaleza de las actividades al aire libre). Los lentes de contacto con
                        bloqueador UV ayudan a proporcionar protección contra la radiación UV perjudicial. Sin embargo, no
                        se
                        han realizado estudios clínicos para demostrar que el uso de lentes de contacto con bloqueador UV
                        reduzca el riesgo de desarrollar cataratas u otros trastornos de la visión.
                        Este es un material promocional publicado y distribuido por ÓPTICAS LOF XDB S.A. Este es un mensaje
                        que
                        llega a usted a través de su profesional de la visión. ESTA INFORMACIÓN NO SUSTITUYE LA CONSULTA
                        MÉDICA.
                        CONSULTE SIEMPRE A SU PROFESIONAL DE LA VISIÓN. Promoción válida en la República Argentina desde el
                        1 al
                        31 de marzo de 2023 o hasta agotar stock de 100 lentes de sol lo que ocurra primero. El Organizador
                        se
                        reserva la facultad de discontinuar la presente promoción, total o parcialmente respecto de uno
                        o
                        más productos incluidos en la misma, en cualquier momento durante su vigencia. *Pack 1: por la
                        compra de
                        4 cajas de ACUVUE OASYS® para astigmatismo con Hydraclear® Plus; ó 4 cajas de ACUVUE OASYS® con
                        Hydraclear® Plus habrá un descuento del 25%. El Pack 1 permite combinar los productos reusables entre
                        sí
                        y la combinación puede incluir como máximo 4 cajas de 2 dioptrías distintas. Se bonificará el
                        producto
                        esférico. **Pack 2: por la compra de 6 cajas de ACUVUE OASYS® con Hydraclear® Plus habrá un
                        descuento del 33%. ***Pack 3: por la compra de 3 cajas de 1-DAY ACUVUE® MOIST; ó 3 cajas de 1-DAY
                        ACUVUE® MOIST para ASTIGMATISMO con LACREON® (Etafilcon A) ó 3 cajas de ACUVUE OASYS® 1-Day with
                        HydraLuxe™ habrá un descuento del 33%. El Pack 3 permite combinar los productos descartables diarios
                        entre sí y la combinación puede incluir como máximo 3 cajas de 2 dioptrías distintas. Se bonificará
                        el
                        producto esférico. Además, por la compra de cualquier pack promocional habrá un 30% de descuento en
                        marcas de lentes de sol seleccionadas por la óptica, hasta agotar stock de 100 lentes de sol. Esta
                        es
                        una promoción exclusiva de ÓPTICAS LOF XDB S.A., CUIT 30709305294, disponible en todas las
                        sucursales de
                        CABA y provincia de Buenos Aires.
                        <br/>
                        Los lentes de contacto ACUVUE® son indicados para la corrección de la visión. Como ocurre con todos
                        los
                        lentes de contacto, pueden desarrollar problemas oculares, inclusive úlceras de córnea. Algunos
                        usuarios
                        sienten irritación, prurito o incomodidad leves. Los lentes no deben ser prescritos si los pacientes
                        tienen infección ocular o sienten incomodidad, tienen lagrimeo excesivo, alteraciones de visión,
                        enrojecimiento u otros problemas oculares. Producto de uso bajo prescripción del profesional de la
                        salud. Hecho en U.S.A. o Irlanda. Para más información sobre uso, cuidado de mantenimiento y
                        seguridad
                        consulte a un profesional de la visión, llame al centro de atención al cliente 08004442604, visite
                        www.acuvue.com.ar, escríbanos a contactenos.ar@acuvue.com o consulte las Guías de Instrucciones para
                        el
                        usuario. Johnson & Johnson de Argentina S.A.C e I. CUIT 30500572309. Mendoza 1259, Belgrano, Ciudad
                        Autónoma de Buenos Aires (C1428DJG). ADVERTENCIA: LOS LENTES DE CONTACTO CON ABSORCIÓN DE RAYOS UV
                        no
                        sustituyen los anteojos protectores con absorción de rayos UV tales como las gafas de protección o
                        anteojos para el sol con absorción UV, debido a que no cubren completamente el ojo y el área de
                        alrededor. Debe continuar usando lentes con absorción de rayos UV, según lo indicado. ACUVUE®;
                        ACUVUE
                        OASYS® con HYDRACLEAR® PLUS; ACUVUE OASYS® para ASTIGMATISMO con HYDRACLEAR® PLUS; 1-DAY ACUVUE®
                        MOIST;
                        1-DAY ACUVUE® MOIST para ASTIGMATISMO con LACREON® (Etafilcon A); ACUVUE OASYS® 1-Day with
                        HYDRALUXE™
                        son marcas registradas de Johnson & Johnson Vision Care Companies, Inc. © Johnson & Johnson de
                        Argentina
                        S.A.C. e I. 2023. Todos los derechos reservados. VEEVA: PP2023AMB464
                    </p>
                </div>
            </div>
        </>
    );
}

export default Footer;