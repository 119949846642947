import { Link } from 'react-router-dom';
import CallStyles from '../../components/call-to-styles/index';
import errorImg from '../../images/error.png';
import { useEffect } from 'react';

const Style = () => {
    useEffect(() => {
        import('./styles.scss');
    }, []);
    return null;
}

function Error(props) {
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    return (
        <div className='error-page '>
            <CallStyles style={Style} />
            <img src={errorImg} alt="imagen de error" />
            <div className='error-content'>
                <h1> 404 error</h1>
                <p> no encontramos la página</p>
                <Link to={"/"}>
                    <button type="button" className='boton d-flex justify-content-center align-items-center' >
                    <p>Seguir navegando</p>
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default Error;

