function Lentillas() {
  return (
    <>
      <div className="hr"></div>
      <footer className="bg-blue d-flex justify-content-xl-center px-lg-4">
        <div className="footer ">
          <p className="py-5 mx-3 mb-0 a-politicas ">
            *Solo los lentes de contacto de prueba son sin cargo. Para la prueba gratis se debe presentar la receta del profesional de la salud. ESTE MATERIAL NO SUSTITUYE LA CONSULTA MÉDICA, ANTE LA MENOR DUDA CONSULTE A SU PROFESIONAL DE LA VISIÓN. Los lentes de contacto ACUVUE® son indicados para la corrección de la visión. Como ocurre con todos los lentes de contacto, pueden desarrollar problemas oculares, inclusive úlceras de córnea. Algunos usuarios sienten irritación, prurito o incomodidad leves. Los lentes no deben ser prescritos si los pacientes tienen infección ocular o sienten incomodidad, tienen lagrimeo excesivo, alteraciones de visión, enrojecimiento u otros problemas oculares. Producto de uso bajo prescripción del profesional de la salud. Hecho en U.S.A. o Irlanda.
            <br />Para más información sobre uso, cuidado de mantenimiento y seguridad consulte a un profesional de la visión, llame al centro de atención al cliente 0800-444-2604, visite www.acuvue.com.ar, escríbanos a contactenos.ar@acuvue.com o consulte la Guía de Instrucciones para el usuario. Johnson & Johnson de Argentina S.A.C e I. CUIT 30-50057230-9. Mendoza 1259, Belgrano, Ciudad Autónoma de Buenos Aires (C1428DJG). ADVERTENCIA: LOS LENTES DE CONTACTO CON ABSORCIÓN DE RAYOS UV no sustituyen los anteojos protectores con absorción de rayos UV tales como las gafas de protección o anteojos para el sol con absorción UV, debido a que no cubren completamente el ojo y el área de alrededor. Debe continuar usando lentes con absorción de rayos UV, según lo indicado. NOTA: La exposición a largo plazo a la radiación UV es uno de los factores de riesgo asociados a las cataratas. La exposición se basa en una serie de factores tales como las condiciones ambientales (altitud, geografía, nubosidad) y los factores personales (alcance y naturaleza de las actividades al aire libre). Los lentes de contacto con bloqueador UV ayudan a proporcionar protección contra la radiación UV perjudicial. Sin embargo, no se han realizado estudios clínicos para demostrar que el uso de lentes de contacto con bloqueador UV reduce el riesgo de desarrollar cataratas u otros trastornos de la visión. Consulte a su profesional de la visión para obtener más información. Veeva: 2024PP04674

          </p>
        </div>
      </footer >
    </>

  );
}

export default Lentillas;
