

function Info(){
    return(
        <>
            <div className="containerMod mx-auto">
                <section>
                <div className="bg-blue">
                    <div className="container py-4 px-lg-5">
                        <div className="row justify-content-between py-5 py-md-5 px-4 px-lg-0">
                            <div className="col-12 col-md-6 pt-2 pt-md-4 pr-mod px-2">
                                <h4 className="text-white mb-5 h4-eye font_ANB">
                                    ¿QUÉ SON?
                                </h4>
                                <p className="text-white text-left p-eye-col font_ANR">
                                    Cada ojo es diferente y <span className="font_ANB">ACUVUE®</span> se inspira en todos ellos.
                                    Por eso, todos nuestros lentes de contacto presentan <span className="font_ANB">innovaciones<br/>EYE-INSPIRED™</span>, una tecnología avanzada pensada para trabajar en armonía con
                                    tus
                                    ojos.
                                    <br/>
                                    <br/>
                                    Todo ello forma parte de nuestro compromiso continuo en ofrecerte una visión nítida,
                                    cómoda
                                    y saludable,
                                    para que puedas enfocarte en tu vida y no en tus lentes.
                                </p>
                            </div>
                            <hr className="w-100 hrCarrera mx-2 my-5"/>
                            <div className="col-12 col-md-6 pt-3 pl-mod px-2 borderNone">
                                <h4 className="text-white mb-5 h4-eye font_ANB">
                                    ¿CÓMO FUNCIONAN?
                                </h4>
                                <p className="text-white text-left p-eye-col font_ANR">
                                    Estas innovaciones exclusivas se encuentran en los lentes de contacto de la marca <span
                                        className="font_ANB">ACUVUE®</span> y se diseñaron para ayudar a
                                    ofrecer
                                    comodidad, visión
                                    nítida y salud ocular.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
            </div>
        </>
    );
}

export default Info;