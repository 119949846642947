function Lentillas() {
  return (
    <footer className="bg-blue">
      <a target="_blank" rel="noreferrer" href="https://www.acuvue.com.ar/politica-privacidad"
        className="d-block py-4 mb-0 text-center text-white a-politicas font_ANM">
        Políticas de privacidad
      </a>
    </footer>
  );
}

export default Lentillas;
