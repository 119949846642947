

function Lentes() {
	return (
		<div>
			<div className="bg-eye d-flex pr-sm-0 justify-content-center align-items-center justify-content-sm-center">
				<h2 className="text-white mb-0 h2-eye font-B text-center">EYE-INSPIRED™ <br /> INNOVATIONS</h2>
			</div>
			<div className="bg-violet bg-lg">
				<div className="container py-4 px-lg-5">
					<div className="row  text-lentes row-cols-lg-3 flex-lg-nowrap py-3 py-md-5 px-4 px-lg-0 justify-content-evenly">
						<div className="col-lg-6 text-left">
							<h4 className="text-white mb-4 h4-eye font-B">¿QUÉ SON?</h4>
							<p className="text-white font-R">
								Cada ojo es diferente y <span className="bold">ACUVUE®</span> se inspira en todos ellos.
								Por eso, todos nuestros lentes de contacto presentan <span className="bold">innovaciones
									eye-inspired™</span>, una tecnología avanzada pensada para trabajar en armonía con tus
								ojos.
								<br />
								<br />
								Todo ello forma parte de nuestro compromiso continuo en ofrecerte una visión nítida, cómoda y saludable,
								para que puedas enfocarte en tu vida y no en tus lentes.
							</p>
						</div>
						<span className=" hr-white my-4 my-lg-0"></span>
						<div className="col-lg-6 text-right">
							<h4 className="text-white mb-4 h4-eye font-B">¿CÓMO FUNCIONAN?</h4>
							<p className="text-white font-R">
								Estas innovaciones exclusivas se encuentran en los lentes de contacto de la marca
								<span className="bold"> ACUVUE®</span> y se diseñaron para ayudar a ofrecer comodidad, visión
								nítida y salud ocular.
							</p>
						</div>


					</div>
				</div>
			</div>
		</div>
	);
}

export default Lentes;
