
import Opticas from "./Optica.js";
import datos from "./optica.json";
import ReactGA from "react-ga4";
import Close from '../../../images/close.webp';
ReactGA.initialize("G-E3WG9B2NTJ");
function Shops() {
	const handleDownload = () => {
		window.open("voucher.pdf", "_blank");
		ReactGA.event({
			category: "Download",
			action: "Download voucher Eventos Acuvue",
		});
	}
	return (
		<section className=" opticas">
			<Opticas data={datos} />
			<div className="my-4 text-center">
				<button
					className="btn-pedi p-2 px-4 px-sm-5 py-sm-3 text-center mb-5 mt-3 d-inline-block font_ANB"
					type="button"
					data-toggle="modal"
					data-target="#voucher"
					onClick={handleDownload}
				>
					DESCARGÁ TU VOUCHER
					<br />
					<span className="font_ANR">Y PEDÍ TU</span> PRUEBA SIN CARGO*
				</button>
				<p className="p-pedi text-center mx-auto font_ANR">
					* Sólo los lentes de contacto de prueba son sin cargo. Disponible en profesionales de la visión adheridos en
					esta campaña piloto. No están incluidos costos de consulta, exámenes, adaptación y adquisición de lentes de
					contacto. Los lentes de contacto de prueba sin cargo forman parte del proceso de adaptación y no pueden ser
					entregados fuera de este procedimiento.
				</p>
			</div>
			<div className="modal modal-pedi fade" id="voucher" tabIndex="-1" aria-labelledby="voucherLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content mx-2  ">
						<div className="modal-header border-0 flex-column px-4">
							<a href="index.html" className="ml-auto mt-0 mt-sm-2">
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true"><img className="img-close-x" src={Close} alt="" /></span>
								</button>
							</a>
							<div>
								<h6 className="modal-title mt-0 mt-sm-3" id="exampleModalLabel"><strong>¡Gracias por descargar tu voucher!</strong>
								</h6>
								<p className="headerp mt-n2 mb-0">
									Podés encontrarlo en tus descargas.
								</p>
							</div>
							<hr className="w-100 mb-0 mt-0 bg-warning custom" />
						</div>
						<div className="modal-body px-4 pt-1">
							<h3 className="textoModalCuponUno mb-2">Paso a paso durante tu consulta</h3>
							<div className="row wrapper-list py-2">
								<div className="col-md-12 py-1 py-sm-4">
									<div className="numeroModal rounded-circle d-flex justify-content-center align-items-center float-left">
										<h2 className="text-white font-weight-bold mb-0">1</h2>
									</div>
									<div className="w-100">
										<p className="textoModalCupon">
											<span>El profesional de la vision te hará preguntas.</span><br />
											Te preguntarán sobre tus pasatiempos, actividades y estilo de vida. El profesional de la vision
											está tratando de determinar cuál es el mejor lente para ti.
										</p>
									</div>
								</div>
								<div className="col-md-12 py-1 py-sm-4">
									<div className="numeroModal rounded-circle d-flex justify-content-center align-items-center float-left">
										<h2 className="text-white font-weight-bold mb-0">2</h2>
									</div>
									<div className="w-100">
										<p className="textoModalCupon">
											<span>Tomará mediciones.</span><br />
											Controlará la salud de tus ojos y también tomará mediciones.
										</p>
									</div>
								</div>
								<div className="col-md-12 py-1 py-sm-4">
									<div className="numeroModal rounded-circle d-flex justify-content-center align-items-center float-left">
										<h2 className="text-white font-weight-bold mb-0">3</h2>
									</div>
									<div className="w-100">
										<p className="textoModalCupon">
											<span>Evaluará cual es el mejor tipo de lentes de contacto.</span><br />
											Una vez que te decidas, el profesional de la visión medirá el diámetro y la curvatura de tus ojos.
											Una vez que te hayan adaptado los lentes, revisará tu visión una vez más.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Shops;
