import { useState, useEffect } from 'react';

function CallStyles({ style }) {
    const [isStyle, setIsStyleActive] = useState(false);

    useEffect(() => {
        setIsStyleActive(true);
        return () => setIsStyleActive(false);
    }, [isStyle]);
    const Style = style;

    return (
        <>
            {isStyle && <Style />}
        </>
    )
}
export default CallStyles;