import Runner from '../../../images/runner.webp';

function Header(){
    return(
        <>
            <div className="containerMod mx-auto">
                <header>
                <div className="w-100">
                    <img className="img-fluid w-100" src={Runner} alt=""/>
                </div>
            </header>
            </div>
        </>
    );
}

export default Header;