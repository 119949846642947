function Mensaje() {
  return (
    <div>
      <div className="bg-skyblue py-4 px-4">
        <div className="div-visita mx-auto my-3">
          <p className="text-white text-center mb-0 py-4 px-3 p-visita font_ANR">
            <span className="font_ANB">VISITÁ A TU PROFESIONAL DE LA VISIÓN PARA REALIZAR EL EXAMEN VISUAL ANUAL.</span>
            <br />
            ESTE ES UN MENSAJE DE SALUD PÚBLICA A LA COMUNIDAD. ANTE LA MENOR DUDA CONSULTE A SU PROFESIONAL DE LA VISIÓN.
          </p>
        </div>
      </div>
      <div className="container pt-4 pb-5 px-4 px-sm-5">
        <p className="p-aster text-center my-4 my-sm-5 font_ANR">
          *Euromonitor International; Edición Eyewear 2021; valor de las ventas a rsp, todos los canales minoristas, datos
          de 2019; La “familia de marcas Acuvue” representa las ventas agregadas de las siguientes marcas a nivel de GBN:
          1-Day ACUVUE®, ACUVUE® Oasys, ACUVUE®, and ACUVUE® 2. **Solo los lentes de contacto de prueba son sin cargo. Para
          la prueba sin cargo se debe presentar la receta del profesional de la salud. Los lentes de contacto ACUVUE® son
          indicados para la corrección de la visión. Producto de uso bajo prescripción del profesional de la salud.
          <br />
          <span className="font_ANB">ADVERTENCIA</span>: LOS LENTES DE CONTACTO CON ABSORCIÓN DE RAYOS UV no sustituyen los
          anteojos protectores con absorción de rayos UV tales como las gafas de protección o anteojos para el sol con
          absorción UV, debido a que no cubren completamente el ojo y el área de alrededor. Debe continuar usando lentes con
          absorción de rayos UV, según lo indicado. © 2022 Johnson & Johnson de Argentina S.A.C. e I. Todos los derechos
          reservados (incluidas las imágenes). Las imágenes tienen un valor meramente ejemplar o referencial y no pretenden
          ser una representación fiel de la realidad. VEEVA: PP2022AMB6035
        </p>
      </div>
    </div>
  );
}

export default Mensaje;
