import Eye1 from '../../../images/eye1.webp';
import Eye2 from '../../../images/eye2.webp';
import Eye3 from '../../../images/eye3.webp';
import Eye4 from '../../../images/eye4.webp';
import Eye5 from '../../../images/eye5.webp';

function Lentes(){
    return(
        <>
            <div className="containerMod mx-auto">
                <section className="bg-gray-mod">
                <div className="container py-mod px-3 px-lg-4">
                    <div className="d-flex flex-row flex-wrap px-3 justify-content-center">
                        <div className="d-flex width-col-eye flex-wrap flex-sm-nowrap mb-5 mt-4">
                            <div className="">
                                <img className="img-eye-mod" src={Eye1} alt=""/>
                            </div>
                            <div className="pt-2 pt-sm-4 pl-2 pl-sm-3 carreraMod">
                                <h5 className="h5-lente-eye font_ANDB">
                                    ANTIOXIDANTES
                                </h5>
                                <p className="sub-lente-eye font_ANDB">
                                    PARA MANTENER LA COMODIDAD
                                </p>
                                <p className="d-none d-sm-block p-lente-eye font_ANR">
                                    Aprovechamos el poder de los antioxidantes y los incorporamos a nuestros lentes de
                                    contacto,
                                    con una
                                    capacidad antioxidante cuatro veces superior a la de la vitamina E2.² Esto ayuda a
                                    mantener
                                    en su estado
                                    natural un importante componente de la película lagrimal para garantizar la comodidad
                                    durante el uso de
                                    los lentes.
                                </p>
                            </div>
                            <div className="d-block d-sm-none mt-3 carreraMod">
                                <p className="p-lente-eye font_ANR">
                                    Aprovechamos el poder de los antioxidantes y los incorporamos a nuestros lentes de
                                    contacto,
                                    con una
                                    capacidad antioxidante cuatro veces superior a la de la vitamina E2.² Esto ayuda a
                                    mantener
                                    en su estado
                                    natural un importante componente de la película lagrimal para garantizar la comodidad
                                    durante el uso de
                                    los lentes.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex width-col-eye flex-wrap flex-sm-nowrap mb-5 mt-3">
                            <div className="">
                                <img className="img-eye-mod" src={Eye2} alt=""/>
                            </div>
                            <div className="pt-2 pt-sm-4 pl-2 pl-sm-3 carreraMod">
                                <h5 className="h5-lente-eye font_ANDB">
                                    AGENTE HUMECTANTE
                                </h5>
                                <p className="sub-lente-eye font_ANDB">
                                    PARA UNA MEJOR HIDRATACIÓN
                                </p>
                                <p className="d-none d-sm-block p-lente-eye font_ANR">
                                    Nuestros lentes de contacto incorporan un avanzado ingrediente humectante para ayudar a
                                    que
                                    tus ojos se
                                    mantengan hidratados durante todo el día.¹³
                                </p>
                            </div>
                            <div className="d-block d-sm-none mt-3 carreraMod">
                                <p className="p-lente-eye font_ANR">
                                    Nuestros lentes de contacto incorporan un avanzado ingrediente humectante para ayudar a
                                    que
                                    tus ojos se
                                    mantengan hidratados durante todo el día.¹³
                                </p>
                            </div>
                        </div>
                        <div className="d-flex flex-column width-col-eye mb-5 mt-3">
                            <div className="d-flex flex-nowrap">
                                <div className="">
                                    <img className="img-eye-mod" src={Eye3} alt=""/>
                                </div>
                                <div className="pt-2 pt-sm-4 pl-2 pl-sm-3 carreraMod">
                                    <h5 className="h5-lente-eye font_ANDB">
                                        ESTABILIZACIÓN MEDIANTE EL <br/> PARPADEO PARA LENTES TÓRICOS:
                                    </h5>
                                    <p className="sub-lente-eye font_ANDB">
                                        VISIÓN ESTABLE PARA PACIENTES ASTÍGMATAS
                                    </p>
                                    <p className=" d-none d-sm-block p-lente-eye font_ANR">
                                        Los pacientes con astigmatismo necesitan que sus lentes se mantengan estables a lo
                                        largo
                                        del día, pero
                                        los movimientos naturales de cabeza y ojos pueden hacerlos girar. Nuestros lentes
                                        para
                                        astigmatismo
                                        tienen 4 zonas de estabilización que trabajan de forma natural con los párpados,
                                        aprovechando la fuerza
                                        del parpadeo, ayudando a mantener los lentes en su lugar.
                                    </p>
                                </div>
                            </div>
                            <div className="d-block d-sm-none mt-3 carreraMod">
                                <p className="p-lente-eye font_ANR">
                                    Los pacientes con astigmatismo necesitan que sus lentes se mantengan estables a lo largo
                                    del
                                    día, pero los
                                    movimientos naturales de cabeza y ojos pueden hacerlos girar. Nuestros lentes para
                                    astigmatismo tienen 4
                                    zonas de estabilización que trabajan de forma natural con los párpados, aprovechando la
                                    fuerza del
                                    parpadeo, ayudando a mantener los lentes en su lugar.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex width-col-eye flex-wrap flex-sm-nowrap mt-4 mb-5">
                            <div className="">
                                <img className="img-eye-mod" src={Eye4} alt=""/>
                            </div>
                            <div className="pt-2 pt-sm-4 pl-2 pl-sm-3 carreraMod">
                                <h5 className="h5-lente-eye font_ANDB">
                                    BORDE INFINITO
                                </h5>
                                <p className="sub-lente-eye font_ANDB">
                                    PARA UNA MAYOR COMODIDAD
                                </p>
                                <p className="d-none d-sm-block p-lente-eye font_ANR">
                                    Nuestros lentes de contacto tienen un borde ultrafino que se ajusta a la forma natural
                                    del
                                    ojo para
                                    garantizar una máxima comodidad y una sensación casi imperceptible al usar los lentes.
                                </p>
                            </div>
                            <div className="d-block d-sm-none mt-3 carreraMod">
                                <p className="p-lente-eye font_ANR">
                                    Nuestros lentes de contacto tienen un borde ultrafino que se ajusta a la forma natural
                                    del
                                    ojo para
                                    garantizar una máxima comodidad y una sensación casi imperceptible al usar los lentes.
                                </p>
                            </div>
                        </div>
                        <div className="d-flex width-col-eye flex-wrap flex-sm-nowrap mt-4">
                            <div className="">
                                <img className="img-eye-mod" src={Eye5} alt=""/>
                            </div>
                            <div className="pt-2 pt-sm-4 pl-2 pl-sm-3 carreraMod">
                                <h5 className="h5-lente-eye font_ANDB">
                                    PROTECCIÓN UV**
                                </h5>
                                <p className="sub-lente-eye font_ANDB">
                                    PARA LA SALUD OCULAR
                                </p>
                                <p className="d-none d-sm-block p-lente-eye font_ANR">
                                    Estamos comprometidos en ayudar a preservar tu salud ocular, incorporando un avanzado
                                    ingrediente de
                                    absorción UV que ayuda a bloquear la radiación UV.
                                </p>
                            </div>
                            <div className="d-block d-sm-none mt-3 carreraMod">
                                <p className="p-lente-eye font_ANR">
                                    Estamos comprometidos en ayudar a preservar tu salud ocular, incorporando un avanzado
                                    ingrediente de
                                    absorción UV que ayuda a bloquear la radiación UV.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="bg-eye d-flex pr-sm-0 justify-content-center align-items-center justify-content-sm-center borderEye">
                    <h2 className="text-white mb-0 h2-eye font_ANB text-center">
                        EYE-INSPIRED™ <br/> INNOVATIONS
                    </h2>
                </div>
            </section>
            </div>
        </>
    );
}

export default Lentes;