
import Opticas from "./Optica.js";
import datos from "./optica.json";
import ReactGA from "react-ga4";
ReactGA.initialize("G-E3WG9B2NTJ");

function Shops() {
    const handleDownload = () => {
        window.open("voucheroct23.pdf", "_blank");
        ReactGA.event({
            category: "Download",
            action: "Download voucher La Pareja Perfecta",
        });
    }
    return (
        <section className=" opticas">
            <Opticas data={datos} />
            <div className="mt-3 d-flex justify-content-center">
                <button type="button" data-bs-toggle="modal" data-bs-target="#voucher" className="boton d-flex flex-column justify-content-center align-items-center" onClick={handleDownload}>
                    <p>Descargá tu cupón </p>
                    <p><span>y pedí tu </span>prueba sin cargo*</p>
                </button>
            </div>
            <div className="modal fade" id="voucher" tabIndex="-1" aria-labelledby="voucherLabel" aria-hidden="true">
                <div className="modal-dialog d-flex justify-content-center">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title" id="voucherLabel">TU CUPÓN DE DESCUENTO TE ESTÁ ESPERANDO</h5>
                            <hr />
                            <p>Presentá el voucher en</p>
                            <p>tu óptica adherida más cercana </p>
                            <p>y enterate de nuestra </p>
                            <p><span>promo exclusiva</span></p>
                        </div>
                        <div className="modal-footer justify-content-center">
                            <p>Encontralo en tus descargas</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Shops;
