import Header from '../../components/sportclub/Header/index';
import Footer from '../../components/sportclub/Footer/index';
import Opticas from '../../components/sportclub/Opticas/index';
import Lentes from '../../components/sportclub/Lentes/index';
import Lentillas from '../../components/sportclub/Lentillas/index';
import Bootstrap5 from '../../components/call-to-bootstrap/index';
import CallStyles from '../../components/call-to-styles/index';
import { useEffect } from 'react';

const call = {
    css: 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css',
    js: 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js',
};

const Style = () => {
    useEffect(() => {
        import('./styles.scss');
    }, []);
    return null;
}

function Sportclub(props) {
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    return (
        <>
            <CallStyles style={Style} />
            <Bootstrap5 cssCall={call.css} jsCall={call.js} />
            <Header />
            <Opticas />
            <Lentes />
            <Lentillas />
            <Footer />
        </>
    );
}

export default Sportclub;