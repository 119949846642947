import AcuvueLogo from '../../../images/Header/acuvue-logo.svg';
import CajaLentes from '../../../images/lentes-sportclub.png';

function Header() {

    return (
        <header className="bg-violet text-header text-center d-flex flex-column">
            <div className="container-fluid ">
                <div className="row row-cols-lg-2 flex-lg-nowrap mt-3 justify-content-lg-center">
                    <div className="col-lg-5 boxes"><img src={CajaLentes} alt="caja de lentes" /></div>
                    <div className="text-1 mt-2 col-lg-7">Disfrutá cada momento<br />con los <span className="bold">lentes de contacto <br /><span className="text-1-span">ACUVUE® VITA™.</span></span></div>
                </div>
                <div className="row row-cols-lg-2">
                    <div className="bold d-flex flex-column align-items-center txt">
                        <p className="text-2 mt-3">Máxima hidratación durante todo el mes.¹</p>
                        <p className="text-3 mt-2">VISITÁ A TU PROFESIONAL DE LA VISIÓN PARA REALIZAR EL EXAMEN DE SALUD VISUAL ANUAL. <br className="br" />ESTE ES UN MENSAJE DE SALUD PÚBLICA A LA COMUNIDAD.</p>
                    </div>
                    <div className="mt-4 row p-0 justify-content-end flex-md-column align-content-md-end">
                        <div className="text-4 col-7 d-flex align-items-center ps-5">
                            <p>VITA™</p>
                        </div>
                        <img className="img-logo col-9" src={AcuvueLogo} alt="Logo Acuvue" />
                    </div>
                </div>
            </div>

        </header >
    );
}

export default Header;
