import $ from "jquery";

function modal() {
  $(".modal").on("show.bs.modal", function (e) {
    var id = $(this).attr("id");
    setTimeout(function () {
      $(".modal-backdrop").attr("data-id", id);
    });
  });
}
function customselect() {
  $("select").select2({
    placeholder: "Selecciona una Estación",
  });
}
function selectorUbicaciones() {
  $("select").on("change", function () {
    var zona = $("select option:selected").val();
    $(".sucursales").hide();
    $(zona).show();
  });
  /*Componente selector de ubicaciones*/
  let sucursales = {
    congresoDeTucuman: [
      {
        nombre: "Pförtner (Cabildo)",
        direccion: "Av. Cabildo 2617",
        link: "https://goo.gl/maps/sXYvfEWat22nutru8",
      },
      {
        nombre: "Paragamian (Cabildo)",
        direccion: "Av. Cabildo 2968",
        link: "https://goo.gl/maps/sXYvfEWat22nutru8",
      },
      {
        nombre: "Laboratorio Óptico Battilana (Cabildo)",
        direccion: "Av. Cabildo 3198 ",
        link: "https://goo.gl/maps/sXYvfEWat22nutru8",
      },
      {
        nombre: "Visión.ar",
        direccion: "Av. Cabildo 3750",
        link: "https://goo.gl/maps/sXYvfEWat22nutru8",
      }
    ],
    juramento: [
      {
        nombre: "Más Visión Cabildo",
        direccion: "Av. Cabildo 1921",
        link: "https://goo.gl/maps/Vh9M8K61vLzvRx4X6",
      },
      {
        nombre: "Lof Belgrano (Juramento)",
        direccion: "Juramento 2441",
        link: "https://goo.gl/maps/Vh9M8K61vLzvRx4X6",
      },
      {
        nombre: "Multiópticas (Cabildo)",
        direccion: "Av. Cabildo 1928",
        link: "https://goo.gl/maps/Vh9M8K61vLzvRx4X6",
      },
      {
        nombre: "Multiópticas (Cabildo)",
        direccion: "Av. Cabildo 2106",
        link: "https://goo.gl/maps/Vh9M8K61vLzvRx4X6",
      },
      {
        nombre: "Óptica Griensu (Juramento)",
        direccion: "Av Juramento 2061",
        link: "https://goo.gl/maps/Vh9M8K61vLzvRx4X6",
      },
      {
        nombre: "Lookout (La Pampa)",
        direccion: "La Pampa 2351 ",
        link: "https://goo.gl/maps/Vh9M8K61vLzvRx4X6",
      },
      {
        nombre: "Optired Cabildo",
        direccion: "Av. Cabildo 2628",
        link: "https://goo.gl/maps/Vh9M8K61vLzvRx4X6",
      },
      {
        nombre: "Farmacia Danesa",
        direccion: "Av. Cabildo 2171",
        link: "https://goo.gl/maps/Vh9M8K61vLzvRx4X6",
      }
    ],
    jHernandez: [
      {
        nombre: "Óptica Social (Ciudad de la Paz)",
        direccion: "Ciudad de La Paz 1405",
        link: "https://goo.gl/maps/VuNtdXKFRF4NG31a9",
      },
      {
        nombre: "Paragamian (Cabildo)",
        direccion: "Av. Cabildo 1567",
        link: "https://goo.gl/maps/VuNtdXKFRF4NG31a9",
      },
      {
        nombre: "Óptica Social (La Pampa)",
        direccion: "La Pampa 2492 ",
        link: "https://goo.gl/maps/VuNtdXKFRF4NG31a9",
      },
      {
        nombre: "Paragamian (Echeverría)",
        direccion: "Echeverría 2270",
        link: "https://goo.gl/maps/VuNtdXKFRF4NG31a9",
      },
      {
        nombre: "Óptica Social (Ayacucho)",
        direccion: "Ayacucho 978",
        link: "https://goo.gl/maps/VuNtdXKFRF4NG31a9",
      },
      {
        nombre: "Óptica Cemic Belgrano (V. Arredondo)",
        direccion: "Virrey Arredondo 2419",
        link: "https://goo.gl/maps/VuNtdXKFRF4NG31a9",
      },
      {
        nombre: "Óptica Lumilens (Echeverria)",
        direccion: "Echeverria 2497",
        link: "https://goo.gl/maps/VuNtdXKFRF4NG31a9",
      },
      {
        nombre: "Lookout (La Pampa)",
        direccion: "La Pampa 2351",
        link: "https://goo.gl/maps/VuNtdXKFRF4NG31a9",
      }
    ],
    palermo: [
      {
        nombre: "Más Visión Jumbo Palermo",
        direccion: "Bullrich 345 ",
        link: "https://goo.gl/maps/3W48THjjdSr8LAm1A",
      },
    ],
    olleros: [
      {
        nombre: "Lof Cañitas (Olleros)",
        direccion: "Olleros 1699  ",
        link: "https://goo.gl/maps/cbfNq1qJ6FcVKeYz7",
      },
      {
        nombre: "Más Visión El Solar de la Abadía",
        direccion: "Arce 940",
        link: "https://goo.gl/maps/cbfNq1qJ6FcVKeYz7",
      },
      {
        nombre: "Hipervisión (Cabildo)",
        direccion: "Av. Cabildo 613",
        link: "https://goo.gl/maps/cbfNq1qJ6FcVKeYz7",
      },
      {
        nombre: "Hipervisión (F. Lacroze)",
        direccion: "Av. Federico Lacroze 2468",
        link: "https://goo.gl/maps/cbfNq1qJ6FcVKeYz7",
      },
      {
        nombre: "Hipervisión (Cabildo)",
        direccion: "Av. Cabildo 1081",
        link: "https://goo.gl/maps/cbfNq1qJ6FcVKeYz7",
      },
    ],
    bulnes: [
      {
        nombre: "Óptica Villalba ",
        direccion: "Av Las Heras 3751",
        link: "https://goo.gl/maps/q1vgmPHD9QkT1ihJ7",
      },
      {
        nombre: "Lof Palermo (J. Salguero)",
        direccion: "Jerónimo Salguero 1989",
        link: "https://goo.gl/maps/q1vgmPHD9QkT1ihJ7",
      },
      {
        nombre: "Lof Coronel Díaz",
        direccion: "Av. Coronel Díaz 1839",
        link: "https://goo.gl/maps/q1vgmPHD9QkT1ihJ7",
      },
      {
        nombre: "Óptica Citilent ",
        direccion: "Arenales 3396",
        link: "https://goo.gl/maps/q1vgmPHD9QkT1ihJ7"
      },
      {
        nombre: "Tecnióptica",
        direccion: "Av Santa Fé 3645",
        link: "https://goo.gl/maps/q1vgmPHD9QkT1ihJ7"
      },
      {
        nombre: "Optical World",
        direccion: "Arenales 3253",
        link: "https://goo.gl/maps/q1vgmPHD9QkT1ihJ7"
      },
      {
        nombre: "Más Visión Alto Palermo",
        direccion: "Santa Fe 3253 ",
        link: "https://www.masvision.com.ar/pages/reservar-cita?storeId=140"
      },
    ],
    aguero: [
      {
        nombre: "Pförtner (Pueyrredón)",
        direccion: "Av. Pueyrredón 1706",
        link: "https://goo.gl/maps/5cszbK6VorpSChea9",
      },
      {
        nombre: "Laboratorio Óptico Battilana (Santa Fe)",
        direccion: "Av Santa Fe 2685 ",
        link: "https://goo.gl/maps/5cszbK6VorpSChea9"
      },
      {
        nombre: "Lutz Ferrando (Callao)",
        direccion: "Callao 1224 ",
        link: "https://goo.gl/maps/5cszbK6VorpSChea9"
      },
    ],
    pueyrredon: [
      {
        nombre: "Lutz Ferrando (Pueyrredón)",
        direccion: "Av Pueyrredón 1689",
        link: "https://goo.gl/maps/aCVLoS164i68vzdp7",
      },
      {
        nombre: "Óptica Look",
        direccion: "Av Pueyrredón 2162",
        link: "https://goo.gl/maps/aCVLoS164i68vzdp7",
      },
      { nombre: "Óptica Regard", direccion: "Av Las Heras 1997", link: "https://goo.gl/maps/aCVLoS164i68vzdp7" },
      {
        nombre: "Óptica del Carmelo ",
        direccion: "Av Pueyrredón 1357 Local 72",
        link: "https://goo.gl/maps/aCVLoS164i68vzdp7",
      },
      { nombre: "Óptica Integral", direccion: "Av Rivadavía 1968", link: "https://goo.gl/maps/aCVLoS164i68vzdp7" },
    ],
    fMedicina: [
      {
        nombre: "Laboratorio Óptico Battilana (Pueyrredón)",
        direccion: "Av Pueyrredón 1095",
        link: "https://goo.gl/maps/5yWBhY4MCMMLyyZ37",
      },
      {
        nombre: "Óptica Social (Ayacucho)",
        direccion: "Ayacucho 978",
        link: "https://goo.gl/maps/5yWBhY4MCMMLyyZ37"
      },
      {
        nombre: "Lutz Ferrando (Pueyrredón)",
        direccion: "Pueyrredón 1224 ",
        link: "https://goo.gl/maps/5yWBhY4MCMMLyyZ37"
      },
      {
        nombre: "Óptica Ios",
        direccion: "Junin 1082",
        link: "https://goo.gl/maps/5yWBhY4MCMMLyyZ37"
      },
      {
        nombre: "Óptika Recoleta",
        direccion: "Azcuenaga 1025",
        link: "https://goo.gl/maps/5yWBhY4MCMMLyyZ37",
      },
      {
        nombre: "Optimus Visión",
        direccion: "Av Santa Fe 2270",
        link: "https://goo.gl/maps/5yWBhY4MCMMLyyZ37",
      },
    ],
    callao: [
      { nombre: "Európtica", direccion: "Av. Córdoba 1820", link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6" },
      {
        nombre: "Vacance (Santa Fe)",
        direccion: "Av Santa Fe 1522",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6",
      },
      {
        nombre: "Lutz Ferrando (Santa Fe)",
        direccion: "Av Santa Fe 1223",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6"
      },
      {
        nombre: "Lutz Ferrando (Callao)",
        direccion: "Callao 1224 ",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6",
      },
      {
        nombre: "Óptica Social (Ayacucho)",
        direccion: "Ayacucho 978",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6"
      },
      {
        nombre: "Óptica Griensu (Santa Fe)",
        direccion: "Av Santa Fe 1429",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6"
      },
      {
        nombre: "Óptica Cemic Barrio Norte (Talcahuano)",
        direccion: "Talcahuano 1279",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6"
      },
      {
        nombre: "Óptica Net",
        direccion: "Arenales 1736 ",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6"
      },
      {
        nombre: "Casa Óptica",
        direccion: "Juncal 1765",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6"
      },
      {
        nombre: "Nueva Visión Barrio Norte",
        direccion: "Av Santa Fe 1615 ",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6"
      },
      {
        nombre: "Ópticas Montenero",
        direccion: "Av Callao 1200",
        link: "https://goo.gl/maps/JckM7DzcmJGBsaPD6"
      },
    ],
    tribunales: [
      {
        nombre: "Lof Tribunales (Uruguay)",
        direccion: "Uruguay 586",
        link: "https://goo.gl/maps/djd4YiQcDZFVCjXS7"
      },
      {
        nombre: "Laboratorio Óptico Battilana (Cordoba)",
        direccion: "Av Cordoba 1538",
        link: "https://goo.gl/maps/djd4YiQcDZFVCjXS7"
      },
      {
        nombre: "Lutz Ferrando (Santa Fe)",
        direccion: "Av Santa Fe 1223 ",
        link: "https://goo.gl/maps/djd4YiQcDZFVCjXS7"
      },
      {
        nombre: "Lutz Ferrando (Paraguay)",
        direccion: "Paraguay 640",
        link: "https://goo.gl/maps/djd4YiQcDZFVCjXS7",
      },
      {
        nombre: "Óptica Neo Visión",
        direccion: "Parana 618",
        link: "https://goo.gl/maps/djd4YiQcDZFVCjXS7",
      },
      {
        nombre: "Óptica Cemic Barrio Norte (Talcahuano)",
        direccion: "Talcahuano 1279 ",
        link: "https://goo.gl/maps/djd4YiQcDZFVCjXS7"
      },
      {
        nombre: "Óptica Luis y Lucio",
        direccion: "Av Corrientes 1246 Local 13",
        link: "https://goo.gl/maps/djd4YiQcDZFVCjXS7",
      },
      {
        nombre: "Optical Work",
        direccion: "Uruguay 989",
        link: "https://goo.gl/maps/djd4YiQcDZFVCjXS7"
      },
    ],
    carlosPellegrini: [
      {
        nombre: "Vacance (Santa Fe)",
        direccion: "Av Santa Fe 976",
        link: "https://goo.gl/maps/CQ92q6Z3aAD4z4Zg6",
      },
      {
        nombre: "Más Visión Florida y San Martín (Lavalle)",
        direccion: "Lavalle 511",
        link: "https://goo.gl/maps/CQ92q6Z3aAD4z4Zg6",
      },
      {
        nombre: "Más Visión Galerías Del Pacífico (Florida)",
        direccion: "Florida 737",
        link: "https://goo.gl/maps/CQ92q6Z3aAD4z4Zg6",
      },
    ],
    nueveDeJulio: [
      {
        nombre: "Vacance (Santa Fe)",
        direccion: "Av Santa Fe 976",
        link: "https://goo.gl/maps/CQ92q6Z3aAD4z4Zg6",
      },
      {
        nombre: "Óptica Lumilens (Lavalle)",
        direccion: "Lavalle 720",
        link: "https://goo.gl/maps/CQ92q6Z3aAD4z4Zg6",
      },
      {
        nombre: "Más Visión Florida y San Martín (Lavalle)",
        direccion: "Lavalle 511",
        link: "https://goo.gl/maps/CQ92q6Z3aAD4z4Zg6"
      },
      {
        nombre: "Más Visión Galerías Del Pacífico (Florida)",
        direccion: "Florida 737",
        link: "https://goo.gl/maps/CQ92q6Z3aAD4z4Zg6"
      }
    ],
    catedral: [
      {
        nombre: "Óptica Lyzeen",
        direccion: "Florida 250 Local 5 y Florida 378 Local 29",
        link: "https://goo.gl/maps/bdabuXzBNyL7ZUuH9",
      },
      {
        nombre: "Óptica Güemes",
        direccion: "Florida 165 Local 3",
        link: "https://goo.gl/maps/bdabuXzBNyL7ZUuH9"
      },
      {
        nombre: "Óptica Visilab",
        direccion: "Florida 362 Local 9",
        link: "https://goo.gl/maps/bdabuXzBNyL7ZUuH9"
      },
      {
        nombre: "Más Visión Florida y San Martín (Lavalle)",
        direccion: "Lavalle 511",
        link: "https://goo.gl/maps/bdabuXzBNyL7ZUuH9"
      }
    ]
  };

  for (const zona in sucursales) {
    $("#zonas").append(
      '<div class="sucursales row justify-content-center" id="' +
      zona +
      '" style="display: none;"></div>'
    );
    for (const sucursal of sucursales[zona]) {
      $("#" + zona).append(`
        <div
          class="col-12 col-sm-5 col-md-3 m-2 caja border"
        >
          <a class="a-style" target="_blank" href="${sucursal.link}">
            <div class="card-body my-4 d-flex flex-row justify-content-between align-items-center px-3">
              <div>
                <h5 class="card-title font_ANDB nombreSucursal">${sucursal.nombre}</h5>
                <h6 class="card-subtitle text-muted font_ANR">${sucursal.direccion}</h6>
              </div>
              <div class="img">
                <img
                  src="images/point.webp"
                  class="rounded icono-pointer"/>
              </div>
            </div>
          </a>
        </div>
        `);
    }
  }

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"let value = params.some_key; // "some_value"
  $("#seleccion").val("#" + params.estacion).change();
}
$(document).ready(function () {
  modal();
  customselect();
  selectorUbicaciones();
  // download();
});