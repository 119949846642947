import Header from '../../components/carrera10klof/Header/index';
import Promo from '../../components/carrera10klof/Promo/index';
import Info from '../../components/carrera10klof/Info/index';
import Lentes from '../../components/carrera10klof/Lentes/index';
import Footer from '../../components/carrera10klof/Footer/index';
import Bootstrap4 from '../../components/call-to-bootstrap/index';
import CallStyles from '../../components/call-to-styles/index';
import { useEffect } from 'react';

import '../../js/app.js';

const call = {
  css: 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css',
  js: 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.bundle.min.js',
  jquery: 'https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js'
};

const Style = () => {
  useEffect(() => {
    import('./styles.scss');
  }, []);
  return null;
}

function Carrera10klof(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return (
    <div className='eventos-acuvue'>
      <CallStyles style={Style} />
      <Bootstrap4 cssCall={call.css} jsCall={call.js} jqueryCall={call.jquery} />
      <Header />
      <Promo />
      <Info />
      <Lentes />
      <Footer />
    </div>
  );
}

export default Carrera10klof;