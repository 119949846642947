function Footer() {
  return (
    <footer className="container">
      <div className="disclaimer mt-5">
          <p>
          <span>ADVERTENCIA:</span> LOS LENTES DE CONTACTO CON ABSORCIÓN UV no sustituyen los anteojos
            protectores con absorción UV tales como las gafas de protección o
            anteojos para el sol con absorción UV, debido a que no cubren
            completamente el ojo y el área de alrededor. Debe continuar usando
            lentes con absorción UV, según lo indicado. NOTA: La exposición a
            largo plazo a la radiación UV es uno de los factores de riesgo
            asociados con las cataratas. La exposición se basa en una serie de
            factores, tales como las condiciones ambientales (altitud,
            geografía, nubosidad) y los factores personales (alcance y
            naturaleza de las actividades al aire libre). Los lentes de contacto
            con bloqueador UV ayudan a proporcionar protección contra la
            radiación UV perjudicial. Sin embargo, no se han realizado estudios
            clínicos para demostrar que el uso de lentes de contacto con
            bloqueador UV reduzca el riesgo de desarrollar cataratas u otros
            trastornos de la visión. Este es un mensaje que llega a usted a
            través de su profesional de la visión. ESTA INFORMACIÓN NO SUSTITUYE
            LA CONSULTA MÉDICA. CONSULTE SIEMPRE A SU PROFESIONAL DE LA VISIÓN.
          </p>
          <p>
            Los lentes de contacto ACUVUE® son indicados para la corrección de
            la visión. Como ocurre con todos los lentes de contacto, pueden
            desarrollar problemas oculares, inclusive úlceras de córnea. Algunos
            usuarios sienten irritación, prurito o incomodidad leves. Los lentes
            no deben ser prescritos si los pacientes tienen infección ocular o
            sienten incomodidad, tienen lagrimeo excesivo, alteraciones de
            visión, enrojecimiento u otros problemas oculares. Producto de uso
            bajo prescripción del profesional de la salud. Hecho en U.S.A. o
            Irlanda. Para más información sobre uso, cuidado de mantenimiento y
            seguridad consulte a un profesional de la visión, llame al centro de
            atención al cliente 08004442604, visite www.acuvue.com.ar,
            escríbanos a contactenos.ar@acuvue.com o consulte las Guías de
            Instrucciones para el usuario. Johnson & Johnson de Argentina S.A.C
            e I. CUIT 30500572309. Mendoza 1259, Belgrano, Ciudad Autónoma de
            Buenos Aires (C1428DJG). Johnson & Johnson de Argentina S.A.C. e I.
            2023. Todos los derechos reservados. VEEVA: PP2023AMB6611
          </p>
      </div>
    </footer>
  );
}

export default Footer;
