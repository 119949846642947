import Logo from "../../../images/Header/acuvue-logo.svg";
import Header1 from "../../../images/Header/header-1.svg";
import Header3 from "../../../images/Header/header-3.svg";
import Facebook from "../../../images/Header/facebook.svg";
import Instagram from "../../../images/Header/Instagram.svg";

function Header() {
  return (
    <header className="position-relative">
      <div className="container-fluid p-0 first-container">
        <div className="row p-4 m-0">
          <div className="col-12 col-xxl-5 trial p-4">
            <img
              className="SvgFirst"
              src={Header1}
              alt="Imagen La pareja perfecta"
            />
          </div>
          <div className="col-sm-12 col-xxl-4 trial p-2 d-flex align-items-center">
            <div className="trial-text">
              <p>CONSULTÁ POR TU</p>
              <p>COMBO DE LENTES DE</p>
              <p>CONTACTO + ANTEOJOS</p>
            </div>
          </div>
          <div className="col-sm-12 col-xxl-3 trial p-2">
            <img
              className="SvgSecond"
              src={Header3}
              alt="Imagen Lentes de contacto"
            />
          </div>
        </div>
        <div className="row m-0 mb-xxl-2">
          <div className="col-11 col-xxl-5 trial text-two justify-content-start p-2">
            <div className="TrialText">
              <p className="first-text">Las mejores cosas de la vida</p>
              <p className="second-text">vienen de a dos.</p>
            </div>
          </div>
          <div className="col-sm-12 col-xxl-7 trial logo-trial justify-content-end pt-2">
            <img className="SvgLogo m-2 align-self-end" src={Logo} alt="Logo Acuvue" />
          </div>
        </div>
        <div className="row m-0">
          <div className="col-12 redes">
            <p className="redes-text-one d-flex align-items-center justify-content-center">
              CONSULTÁ CON TU PROFESIONAL DE LA VISIÓN
            </p>
            <div className="redes-icons d-flex align-items-center">
              <p className="p-1">
                VISITÁ
                <a
                  href="https://www.acuvue.com.ar/"
                  target="_blank"
                  rel="noreferrer"
                  
                >
                  WWW.ACUVUE.COM.AR
                </a>
              </p>
              <div className="redes-icons d-flex align-items-center">
                <a
                  href="https://www.facebook.com/acuvue.ar/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="Logo Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/acuvuear/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Instagram} alt="Logo Instagram" className="m-1" />
                </a>
                <p>/ACUVUEAR</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
